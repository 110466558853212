import { PaginatedListItem } from '../../components/PopularDestinations/PopularDestinations.types';
import {
  IngredientQl,
  ItineraryResponseQl,
  RecipeQl,
} from '../smart-search/types';

export type RecipeQlWithIngredients = Omit<RecipeQl, 'ingredients'> & {
  ingredients: IngredientQl[];
};

export type TripsItineraries = {
  [slug: string]: ItineraryResponseQl | null | undefined;
};

export type LocalizedRecipe = RecipeQl;

export type LocalizedRecipes = {
  [_id: string]: LocalizedRecipe;
};

export type Airports = { [iata: string]: Airport };
export type Harbours = { [iata: string]: Harbour };

export type Airport = { name: string; city: string };
export type Harbour = { name: string; city: string };

export type RecipeData = RecipeQl & {
  numberOfAdults: number;
  numberOfChildren: number;
  totalNights: number | undefined;
  destinations: string[];
  activities: string[];
  isMultiStop: boolean;
  hasFlight: boolean;
  hasHotel: boolean;
  hasFerry: boolean;
  hasActivity: boolean;
};

export type ProductData = Partial<{
  code: string;
  type: ProductType;
  name: string;
  imageURL: string | null;
  rating: number;
  city: string;
  country: string;
}>;

export type ProductType = 'Ticket' | 'Hotel';

export type Amenities = Record<string, boolean | null>;

export type Hotel = {
  HotelCode: string;
  Images: {
    ImageName: string;
  }[];
  MainPolygon: {
    FullName: string;
    Name: string;
  } | null;
  Name: string;
  FullName: string;
  HotelNameSlug: string;
  HotelMainPolygonSlug: string;
  Destination?: {
    DestinationName: string;
    RegionName: string;
    CountryName: string;
  };
  Address?: {
    Address: string | null;
    City: string | null;
    Country: string | null;
    Email: string | null;
    Phone: string | null;
    ZipCode: string | null;
  };
  Description?: {
    Value: string;
  };
  Position?: {
    Latitude: {
      DecimalDegrees: number;
    };
    Longitude: {
      DecimalDegrees: number;
    };
  };
  SemboSunRating?: number;
  TypeOfHotel?: string;
  OpeningParagraph?: { Value?: string };
  HasSembokFactSheetLinked?: boolean;
  Amenities?: Amenities;
};

export type PlacesLists = PaginatedListItem[];

export type PaymentMethod = {
  name: string;
  type: string;
  logoUrl: string;
};

export type AdyenPaymentMethodsResponse = {
  paymentMethods: Array<{ name: string; type: string; brands?: string[] }>;
};

export type HotelFilters = {
  isFamilyProperty?: boolean;
  hasPool?: boolean;
  starRating?: number;
};

export enum ResizeMode {
  FitInside = 'FitInside',
  FitOutside = 'FitOutside',
}

export interface SemboImageOptions {
  resizeMode?: ResizeMode;
  width?: number;
  height?: number;
  timeoutSeconds?: number;
  hotelCode?: string;
  shrinkRectangle?: boolean;
}
