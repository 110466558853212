var t = /* @__PURE__ */ ((n) => (n.DEBUG = "DEBUG", n.INFO = "INFO", n.WARNING = "WARNING", n.ERROR = "ERROR", n))(t || {});
function g(n, o = "UNKNOWN") {
  return typeof n == "string" ? { message: n, eventId: o } : { ...n, eventId: o };
}
const i = "UNKNOWN";
function e(n, o) {
  const r = [
    t.DEBUG,
    t.INFO,
    t.WARNING,
    t.ERROR
  ];
  return r.indexOf(o) >= r.indexOf(n);
}
const R = (n) => (o, r = i) => {
  if (!e(n, t.DEBUG))
    return;
  const N = c(o, r, t.DEBUG);
  console.debug(N);
}, s = (n) => (o, r = i) => {
  if (!e(n, t.INFO))
    return;
  const N = c(o, r, t.INFO);
  console.info(N);
}, u = (n) => (o, r = i) => {
  if (!e(n, t.WARNING))
    return;
  const N = c(o, r, t.WARNING);
  console.warn(N);
}, O = (n) => (o, r = i) => {
  if (!e(n, t.ERROR))
    return;
  const N = c(o, r, t.ERROR);
  console.error(N);
};
function c(n, o, r) {
  const N = g(n, o);
  N.level = r;
  try {
    return JSON.stringify(N);
  } catch (f) {
    return JSON.stringify({ message: "Error in logging", error: f });
  }
}
function E(n) {
  return {
    debug: R(n),
    info: s(n),
    warning: u(n),
    error: O(n)
  };
}
export {
  t as LogLevel,
  E as buildLogger,
  c as createLogText,
  R as logDebug,
  O as logError,
  s as logInfo,
  u as logWarning,
  e as shouldLog
};
