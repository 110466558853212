import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

import { getContentSettingsFromSessionStorage } from '../components/PreviewDogEar/components/ContentSettings/ContentSettings.utils';
import { GetCopyResult } from '../data/sanity/types';

interface Props {
  lang: string;
  microcopy?: GetCopyResult;
  macrocopy?: GetCopyResult;
  microcopyKeys?: boolean;
  macrocopyKeys?: boolean;
  setMicrocopyKeys?: (value: boolean) => void;
  setMacrocopyKeys?: (value: boolean) => void;
  onMissingMicrocopy?: (key: string) => void;
  onMissingMacrocopy?: (key: string) => void;
}

export const TranslationContext = createContext<Partial<Props>>({
  lang: undefined,
  microcopy: undefined,
  setMicrocopyKeys: () => {
    /* noop */
  },
  macrocopy: undefined,
  setMacrocopyKeys: () => {
    /* noop */
  },
  onMissingMicrocopy: () => {
    /* noop */
  },
  onMissingMacrocopy: () => {
    /* noop */
  },
});

export function useTranslationContext(): Partial<Props> {
  return useContext(TranslationContext);
}

export const TranslationProvider: FC<Props & PropsWithChildren<unknown>> = ({
  children,
  ...rest
}) => {
  const [microcopyKeys, setMicrocopyKeys] = useState(false);
  const [macrocopyKeys, setMacrocopyKeys] = useState(false);

  useEffect(() => {
    const settings = getContentSettingsFromSessionStorage();

    setMicrocopyKeys(settings.microcopyKeys);
    setMacrocopyKeys(settings.macrocopyKeys);
  }, []);

  return (
    <TranslationContext.Provider
      value={{
        ...rest,
        microcopyKeys,
        setMicrocopyKeys,
        macrocopyKeys,
        setMacrocopyKeys,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};
