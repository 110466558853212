import { buildLogger, LogLevel } from '@sembo-esg/logging';
import joi from 'joi';

// this file should only be imported by server-side rendering code
interface ServerEnvVariables {
  STATSIG_SERVER_SECRET_KEY: string;
  TRANSLATION_DISABLED: boolean;
  CONSENT_BOOTSTRAP_DISABLED: boolean;
  ENABLE_NODE_METRICS: boolean;
  ENABLE_STDOUT_METRICS: boolean;
  EXTERNAL_CONTENT_API_URL?: string;
  LOG_LEVEL: LogLevel;
}

const validationSchema = joi
  .object<ServerEnvVariables>({
    STATSIG_SERVER_SECRET_KEY: joi.string().required(),
    TRANSLATION_DISABLED: joi.bool(),
    CONSENT_BOOTSTRAP_DISABLED: joi.bool().default(false),
    ENABLE_NODE_METRICS: joi.bool().default(false),
    ENABLE_STDOUT_METRICS: joi.bool().default(false),
    EXTERNAL_CONTENT_API_URL: joi.string().default(null),
    LOG_LEVEL: joi.string().default(LogLevel.WARNING),
  })
  .unknown(true);

const logger = buildLogger(LogLevel.ERROR);

export const getServerValidatedEnv = () => {
  const validation = validationSchema.validate(process.env);
  if (validation.error) {
    logger.error(
      {
        message: 'Error validating client env variables',
        env: validation,
      },
      'SERVER_ENV_VALIDATION_ERROR'
    );

    throw new Error(validation.error.message);
  }
  return validation.value;
};
