import { buildLogger, LogLevel } from '@sembo-esg/logging';

import { getClientValidatedEnv } from '../config/client-env';
import { getServerValidatedEnv } from '../config/server-env';

let logsImpl = buildLogger(LogLevel.INFO);

if (typeof window === 'undefined') {
  const serverEnv = getServerValidatedEnv();
  logsImpl = buildLogger(serverEnv.LOG_LEVEL);
} else {
  const clientEnv = getClientValidatedEnv();
  logsImpl = buildLogger(clientEnv?.LOG_LEVEL ?? LogLevel.WARNING);
}

export const logs = logsImpl;
